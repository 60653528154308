import PublicHomePage from "pages/public/Home";
import { Navigate, Route, Routes } from "react-router-dom";

export default function PublicRouter() {
  return (
    <Routes>
      <Route path="/" element={<PublicHomePage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
