import React, { createContext, useCallback, useContext, useMemo } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import useRevertableData from "react-revertabledata-hook";

const PagedFormContext = createContext();

export function usePagedForm() {
  const context = useContext(PagedFormContext);
  return context;
}

export default function PagedForm({
  defaultData,
  onSubmit,
  formElements = [],
  onlyEdits = false,
  children,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const formDataContainer = useRevertableData(defaultData);
  const {
    currentValues: formData,
    updateValue: updateFormData,
    newValues: newFormData,
  } = formDataContainer;

  const childrenArr = useMemo(() => formElements, [formElements]);

  const pageChildren = useMemo(
    () =>
      childrenArr.filter((child) => child.type.name === PagedForm.Page.name),
    [childrenArr]
  );
  const pages = useMemo(
    () => pageChildren.map((child) => child.props.pageKey),
    [pageChildren]
  );

  const currentPage = useMemo(() => {
    const currentPage = location.pathname.split("/").pop();
    if (!pages.includes(currentPage)) {
      return pages[0];
    }
    return currentPage;
  }, [location.pathname, pages]);
  const nextPage = useMemo(() => {
    const nextPage = pages[pages.indexOf(currentPage) + 1];
    return nextPage;
  }, [currentPage, pages]);
  const prevPage = useMemo(() => {
    const nextPage = pages[pages.indexOf(currentPage) - 1];
    return nextPage;
  }, [currentPage, pages]);
  const currentPageChildIndex = useMemo(
    () => childrenArr.findIndex((child) => child.props.pageKey === currentPage),
    [childrenArr, currentPage]
  );
  const beforeChildren = useMemo(
    () =>
      childrenArr
        .slice(0, currentPageChildIndex)
        .filter((child) => child.type.name !== PagedForm.Page.name),
    [childrenArr, currentPageChildIndex]
  );
  const afterChildren = useMemo(
    () =>
      childrenArr
        .slice(currentPageChildIndex)
        .filter((child) => child.type.name !== PagedForm.Page.name),
    [childrenArr, currentPageChildIndex]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (nextPage) {
        navigate(nextPage);
        return;
      }

      onSubmit(onlyEdits ? newFormData : formData);
    },
    [nextPage, onSubmit, onlyEdits, newFormData, formData, navigate]
  );

  const getFieldProps = useCallback(
    (keychain, valueGetter = (e) => e.target.value) => {
      const keys = keychain.split(".");
      let value = formData;
      for (let key of keys) {
        value = value[key];
      }

      return {
        value,
        onChange: (e) => updateFormData(keychain, valueGetter(e)),
      };
    },
    [formData, updateFormData]
  );

  const gotoPrev = useCallback(() => {
    navigate(prevPage);
  }, [navigate, prevPage]);

  return (
    <PagedFormContext.Provider
      value={{
        data: formData,
        updateData: updateFormData,
        getFieldProps,
        hasNext: !!nextPage,
        hasPrev: !!prevPage,
        gotoPrev,
        handleSubmit,
        beforeChildren,
        pageChildren,
        afterChildren,
        currentPage,
      }}
    >
      {children}
    </PagedFormContext.Provider>
  );
}

function PagedFormOutlet() {
  const { handleSubmit, beforeChildren, pageChildren, afterChildren } =
    useContext(PagedFormContext);

  return (
    <form onSubmit={handleSubmit}>
      {beforeChildren}
      <Routes>
        {pageChildren.map((child) => (
          <Route
            key={child.props.pageKey}
            path={child.props.pageKey}
            element={child}
          />
        ))}
        {pageChildren.slice(0, 1).map((child) => (
          <Route key="*" path="*" element={child.props.component} />
        ))}
      </Routes>
      {afterChildren}
    </form>
  );
}

function PagedFormPage({ pageKey, name, component }) {
  return component;
}

PagedForm.Page = PagedFormPage;
PagedForm.Outlet = PagedFormOutlet;
