import { storage } from "config/firebase";
import { listAll, ref } from "firebase/storage";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useUploadFile } from "react-firebase-hooks/storage";
import { UserContext } from "./UserContext";

export const fileTypes = {
  asset: "asset",
  game: "game",
};

export const AssetContext = createContext();

export default function AssetProvider({ children }) {
  const { userUid } = useContext(UserContext);
  const [uploadAsset, uploading, snapshot, error] = useUploadFile();
  const [fileList, setFileList] = useState([]);

  const userAssetsRef = useMemo(
    () => ref(storage, `${userUid}/${fileTypes.asset}`),
    [userUid]
  );

  const updateList = useCallback(
    function () {
      let running = true;
      async function innerUpdateList() {
        try {
          const res = await listAll(userAssetsRef);
          if (!running) return;
          setFileList([
            ...res.items.map((e) => ({
              path: `https://firebasestorage.googleapis.com/v0/b/gleeforge.appspot.com/o/${e.fullPath.replace(
                /\//g,
                "%2F"
              )}?alt=media`,
              name: e.name,
            })),
          ]);
        } catch (error) {
          console.error(error);
        }
      }
      innerUpdateList();
      return () => {
        running = false;
      };
    },
    [userAssetsRef]
  );

  const upload = useCallback(
    async (file, type = fileTypes.asset) => {
      if (!file) {
        throw new Error("File required");
      }

      const result = await uploadAsset(
        ref(storage, `${userUid}/${type}/${file.name}`),
        file,
        {
          contentType: file.type,
        }
      );
      updateList();
      return result;
    },
    [updateList, uploadAsset, userUid]
  );

  useEffect(() => {
    return updateList();
  }, [updateList]);

  return (
    <AssetContext.Provider value={{ upload, fileList }}>
      {children}
    </AssetContext.Provider>
  );
}
