import { Flex } from "@mantine/core";

export default function HeaderSection({ children, ...props }) {
  return (
    <Flex
      sx={{
        flexGrow: 1,
        flexBasis: 0,
        minWidth: 0,
        alignItems: "center",
      }}
      gap="xs"
      {...props}
    >
      {children}
    </Flex>
  );
}
