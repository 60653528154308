import { createContext, useCallback, useContext } from "react";
import { where } from "firebase/firestore";
import useCollection from "react-firebasecollection-hook";
import { db } from "config/firebase";
import { UserContext } from "./UserContext";
import downloadFile from "downloadfile-js";
import { createGameFile } from "dataFormats/tts";
import { buildBoxArt, createFileFromBase64 } from "util/images";
import { AssetContext, fileTypes } from "./AssetContext";

export const GameContext = createContext();

export default function GameProvider({ children }) {
  const { userUid } = useContext(UserContext);
  const { upload } = useContext(AssetContext);
  const gamesCollection = useCollection(db, "games", {
    queries: [where("owner", "==", userUid || null)],
  });
  const { add, modify } = gamesCollection;
  const addGame = useCallback(
    async (gameData) => {
      const { image, width, height, depth } = await buildBoxArt(
        gameData.cover,
        gameData.bottom,
        gameData.north,
        gameData.south,
        gameData.east,
        gameData.west
      );
      const doc = await add({
        ...gameData,
        box: {
          width,
          height,
          depth,
        },
        owner: userUid,
      });
      await upload(
        createFileFromBase64(image, `${doc.id}-box.png`, "image/png"),
        fileTypes.game
      );
      return doc;
    },
    [userUid, add, upload]
  );
  const modifyGame = useCallback(
    async (id, gameDataChanges) => {
      const realChanges = JSON.parse(JSON.stringify(gameDataChanges));
      if (gameDataChanges.cover) {
        const { image, width, height, depth } = await buildBoxArt(
          gameDataChanges.cover
        );
        realChanges.box = { width, height, depth };
        await upload(
          createFileFromBase64(image, `${id}-box.png`, "image/png"),
          fileTypes.game
        );
      }
      return modify({ id, ...realChanges });
    },
    [modify, upload]
  );
  const exportGame = useCallback(
    (id) => {
      const gameData = gamesCollection.allById[id];
      if (!gameData) {
        throw new Error("No game exists with id " + id);
      }
      downloadFile(
        JSON.stringify(createGameFile(gameData, id)),
        `TTS_Save_${gameData.name}.json`
      );
    },
    [gamesCollection.allById]
  );

  return (
    <GameContext.Provider
      value={{
        ...gamesCollection,
        add: addGame,
        exportGame,
        modify: modifyGame,
      }}
    >
      {children}
    </GameContext.Provider>
  );
}
