import moment from "moment";

function normalizeToMax({ width, height, depth }, max) {
  const whdMax = Math.max(width, height, depth);
  return {
    width: (width / whdMax) * max,
    height: (height / whdMax) * max,
    depth: (depth / whdMax) * max,
  };
}

export function createGameFile(game, id) {
  const now = Date.now();
  return {
    SaveName: game.name,
    EpochTime: Math.floor(now / 1000),
    Date: moment(now).format("M/D/YYYY LTS"),
    VersionNumber: "v13.2.2",
    GameMode: game.name,
    Tags: [],
    Gravity: 0.5,
    PlayArea: 0.5,
    Table: "Table_RPG",
    Sky: "Sky_Field",
    Note: "",
    TabStates: {
      0: {
        title: "Rules",
        body: "",
        color: "Grey",
        visibleColor: {
          r: 0.5,
          g: 0.5,
          b: 0.5,
        },
        id: 0,
      },
      1: {
        title: "White",
        body: "",
        color: "White",
        visibleColor: {
          r: 1.0,
          g: 1.0,
          b: 1.0,
        },
        id: 1,
      },
      2: {
        title: "Brown",
        body: "",
        color: "Brown",
        visibleColor: {
          r: 0.443,
          g: 0.231,
          b: 0.09,
        },
        id: 2,
      },
      3: {
        title: "Red",
        body: "",
        color: "Red",
        visibleColor: {
          r: 0.856,
          g: 0.1,
          b: 0.094,
        },
        id: 3,
      },
      4: {
        title: "Orange",
        body: "",
        color: "Orange",
        visibleColor: {
          r: 0.956,
          g: 0.392,
          b: 0.113,
        },
        id: 4,
      },
      5: {
        title: "Yellow",
        body: "",
        color: "Yellow",
        visibleColor: {
          r: 0.905,
          g: 0.898,
          b: 0.172,
        },
        id: 5,
      },
      6: {
        title: "Green",
        body: "",
        color: "Green",
        visibleColor: {
          r: 0.192,
          g: 0.701,
          b: 0.168,
        },
        id: 6,
      },
      7: {
        title: "Blue",
        body: "",
        color: "Blue",
        visibleColor: {
          r: 0.118,
          g: 0.53,
          b: 1.0,
        },
        id: 7,
      },
      8: {
        title: "Teal",
        body: "",
        color: "Teal",
        visibleColor: {
          r: 0.129,
          g: 0.694,
          b: 0.607,
        },
        id: 8,
      },
      9: {
        title: "Purple",
        body: "",
        color: "Purple",
        visibleColor: {
          r: 0.627,
          g: 0.125,
          b: 0.941,
        },
        id: 9,
      },
      10: {
        title: "Pink",
        body: "",
        color: "Pink",
        visibleColor: {
          r: 0.96,
          g: 0.439,
          b: 0.807,
        },
        id: 10,
      },
      11: {
        title: "Black",
        body: "",
        color: "Black",
        visibleColor: {
          r: 0.25,
          g: 0.25,
          b: 0.25,
        },
        id: 11,
      },
    },
    Grid: {
      Type: 0,
      Lines: false,
      Color: {
        r: 0.0,
        g: 0.0,
        b: 0.0,
      },
      Opacity: 0.75,
      ThickLines: false,
      Snapping: false,
      Offset: false,
      BothSnapping: false,
      xSize: 2.0,
      ySize: 2.0,
      PosOffset: {
        x: 0.0,
        y: 1.0,
        z: 0.0,
      },
    },
    Lighting: {
      LightIntensity: 0.54,
      LightColor: {
        r: 1.0,
        g: 0.9804,
        b: 0.8902,
      },
      AmbientIntensity: 1.3,
      AmbientType: 0,
      AmbientSkyColor: {
        r: 0.5,
        g: 0.5,
        b: 0.5,
      },
      AmbientEquatorColor: {
        r: 0.5,
        g: 0.5,
        b: 0.5,
      },
      AmbientGroundColor: {
        r: 0.5,
        g: 0.5,
        b: 0.5,
      },
      ReflectionIntensity: 1.0,
      LutIndex: 0,
      LutContribution: 1.0,
    },
    Hands: {
      Enable: true,
      DisableUnused: false,
      Hiding: 0,
    },
    ComponentTags: {
      labels: [],
    },
    Turns: {
      Enable: false,
      Type: 0,
      TurnOrder: [],
      Reverse: false,
      SkipEmpty: false,
      DisableInteractions: false,
      PassTurns: true,
    },
    DecalPallet: [],
    LuaScript:
      "--[[ Lua code. See documentation: https://api.tabletopsimulator.com/ --]]\n\n--[[ The onLoad event is called after the game save finishes loading. --]]\nfunction onLoad()\n    --[[ print('onLoad!') --]]\nend\n\n--[[ The onUpdate event is called once per frame. --]]\nfunction onUpdate()\n    --[[ print('onUpdate loop!') --]]\nend",
    LuaScriptState: "",
    XmlUI:
      "<!-- Xml UI. See documentation: https://api.tabletopsimulator.com/ui/introUI/ -->",
    ObjectStates: [createBag(game, id)],
  };
}

function createBag(game, id) {
  const { width, height, depth } = normalizeToMax(game.box, 10);
  return {
    GUID: "",
    Name: "Custom_Model_Bag",
    Transform: {
      posX: 0,
      posY: height,
      posZ: 0,
      rotX: 0,
      rotY: 0,
      rotZ: 0,
      scaleX: 1.0,
      scaleY: 1.0,
      scaleZ: 1.0,
    },
    Nickname: "",
    Description: "",
    GMNotes: "",
    AltLookAngle: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    ColorDiffuse: {
      r: 1.0,
      g: 1.0,
      b: 1.0,
    },
    LayoutGroupSortIndex: 0,
    Value: 0,
    Locked: false,
    Grid: true,
    Snap: true,
    IgnoreFoW: false,
    MeasureMovement: false,
    DragSelectable: true,
    Autoraise: true,
    Sticky: true,
    Tooltip: true,
    GridProjection: false,
    HideWhenFaceDown: false,
    Hands: false,
    MaterialIndex: -1,
    MeshIndex: -1,
    Number: 0,
    CustomMesh: {
      MeshURL: `http://res.gleeforge.com/3d/rect?width=${width}&height=${height}&depth=${depth}`,
      DiffuseURL: `https://firebasestorage.googleapis.com/v0/b/gleeforge.appspot.com/o/${game.owner}%2Fgame%2F${id}-box.png?alt=media`,
      NormalURL: "",
      ColliderURL: "",
      Convex: true,
      MaterialIndex: 3,
      TypeIndex: 6,
      CustomShader: {
        SpecularColor: {
          r: 1.0,
          g: 1.0,
          b: 1.0,
        },
        SpecularIntensity: 0.0,
        SpecularSharpness: 2.0,
        FresnelStrength: 0.0,
      },
      CastShadows: true,
    },
    Bag: {
      Order: 0,
    },
    LuaScript: "",
    LuaScriptState: "",
    XmlUI: "",
  };
}
