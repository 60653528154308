import { useContext } from "react";
import PageLayout from "component/PageLayout";
import UserHeader from "headers/UserHeader";
import { GameForm, emptyGameData } from "forms/Game";
import { GameContext } from "context/GameContext";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PagedForm from "component/PagedForm";
import GameNavbar from "navbars/GameNavbar";

export default function AddGamePage() {
  const navigate = useNavigate();
  const { add: addGame } = useContext(GameContext);

  const handleAdd = useCallback(
    async (newGame) => {
      await addGame(newGame);
      navigate("/games");
    },
    [addGame, navigate]
  );

  return (
    <PagedForm
      onSubmit={handleAdd}
      defaultData={emptyGameData}
      formElements={GameForm()}
    >
      <PageLayout header={<UserHeader />} navbar={<GameNavbar />}>
        <PagedForm.Outlet />
      </PageLayout>
    </PagedForm>
  );
}
