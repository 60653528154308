import { ActionIcon, Avatar } from "@mantine/core";
import { IconLogout } from "@tabler/icons-react";
import { UserContext } from "context/UserContext";
import { useContext } from "react";
import CommonHeader from "./CommonHeader";

export default function UserHeader({ children }) {
  const { signOut, userInfo } = useContext(UserContext);

  return (
    <CommonHeader>
      {children}
      <Avatar src={userInfo.photoURL} alt={userInfo.displayName} />
      <ActionIcon variant="outline" title="Log In" onClick={() => signOut()}>
        <IconLogout />
      </ActionIcon>
    </CommonHeader>
  );
}
