export async function buildBoxArt(
  coverUrl,
  bottomUrl,
  northUrl,
  southUrl,
  eastUrl,
  westUrl
) {
  const [coverImg, bottomImg, northImg, southImg, eastImg, westImg] =
    await Promise.all(
      [coverUrl, bottomUrl, northUrl, southUrl, eastUrl, westUrl]
        .filter((e) => e)
        .map(async (url) => {
          console.log(url);
          const response = await fetch(url, { mode: "cors", method: "GET" });
          const blob = await response.blob();
          return new Promise((resolve, reject) => {
            const img = document.createElement("img");
            img.onload = function () {
              resolve(img);
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(blob);
          });
        })
    );

  const width = coverImg.width;
  const height =
    (northImg || southImg || eastImg || westImg)?.height ||
    Math.floor(Math.min(coverImg.width, coverImg.height) / 2);
  const depth = coverImg.height;

  const canvas = document.createElement("canvas");
  canvas.width = width * 2 + height;
  canvas.height = 2 * width + 2 * depth;

  const ctx = canvas.getContext("2d");

  // Draw background
  ctx.fillStyle = "black";
  ctx.beginPath();
  ctx.rect(0, 0, canvas.width, canvas.height);
  ctx.fill();
  ctx.closePath();

  if (coverImg) {
    // Draw cover
    ctx.drawImage(coverImg, width + height, width);
  }
  if (bottomImg) {
    ctx.drawImage(bottomImg, 0, width);
  }
  if (northImg) {
    ctx.drawImage(await rotateImage(northImg), width, 0);
  }
  if (southImg) {
    ctx.drawImage(await rotateImage(southImg), width, width + depth);
  }
  if (eastImg) {
    ctx.drawImage(await rotateImage(eastImg), width, 2 * width + depth);
  }
  if (westImg) {
    ctx.drawImage(await rotateImage(westImg), width, width);
  }

  // Extract data
  const image = canvas.toDataURL("image/png");
  return { image, width, height, depth };
}

export function createFileFromBase64(base64String, fileName, fileType) {
  const byteString = atob(base64String.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new File([ab], fileName, {
    type: fileType,
  });
}

function rotateImage(image) {
  const rotation = Math.PI / 2;
  return new Promise((resolve) => {
    // Create a canvas element
    const canvas = document.createElement("canvas");

    // Set the canvas size to match the image size
    canvas.width = image.height;
    canvas.height = image.width;

    // Get the 2D context of the canvas
    const context = canvas.getContext("2d");

    // Save the current canvas state
    context.save();

    // Translate the canvas to the center of the image
    context.translate(canvas.width / 2, canvas.height / 2);

    // Rotate the canvas by the given rotation (in radians)
    context.rotate(rotation);

    // Draw the image onto the canvas
    context.drawImage(image, -image.width / 2, -image.height / 2);

    // Restore the previous canvas state
    context.restore();

    // Create a new image from the canvas data
    const rotatedImage = new Image();

    // Set the source of the image to the canvas data URL
    rotatedImage.src = canvas.toDataURL();

    // When the image has finished loading, resolve the Promise with the rotated image
    rotatedImage.onload = () => resolve(rotatedImage);
  });
}
