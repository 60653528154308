import { ActionIcon, Title } from "@mantine/core";
import { IconCards } from "@tabler/icons-react";
import HeaderSection from "component/HeaderSection";
import { Link } from "react-router-dom";

export default function CommonHeader({ children }) {
  return (
    <>
      <HeaderSection>
        <ActionIcon variant="filled" color="green" component={Link} to="/">
          <IconCards />
        </ActionIcon>
        <Title>GleeForge</Title>
      </HeaderSection>
      <HeaderSection justify="end">{children}</HeaderSection>
    </>
  );
}
