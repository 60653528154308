import { Button, Image, Stack, Title } from "@mantine/core";
import PageLayout from "component/PageLayout";
import { GameContext } from "context/GameContext";
import UserHeader from "headers/UserHeader";
import { useContext, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

export default function ViewGamePage() {
  const { id } = useParams();
  const { allById: gamesById, exportGame } = useContext(GameContext);
  const game = useMemo(() => gamesById[id], [gamesById, id]);

  if (!game) return <div>Loading...</div>;

  return (
    <PageLayout header={<UserHeader />}>
      <Stack>
        <Title>{game.name}</Title>
        <Image src={game.cover} height={150} width="fit-content" />
        <Button onClick={() => exportGame(id)}>Export</Button>
        <Button component={Link} to={`/games/edit/${id}`}>
          Edit
        </Button>
      </Stack>
    </PageLayout>
  );
}
