import { auth } from "config/firebase";
import { createContext, useMemo } from "react";
import {
  useAuthState,
  useSignInWithGoogle,
  useSignOut,
} from "react-firebase-hooks/auth";

export const UserContext = createContext();

export const Statuses = {
  loading: "loading",
  errored: "errored",
  loaded: "loaded",
  notAuthed: "notAuthed",
};

export default function UserProvider({ children }) {
  const [userInfo, loadingUser, errorLoadingUser] = useAuthState(auth);
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const [signOut] = useSignOut(auth);

  const userUid = useMemo(() => userInfo?.uid, [userInfo]);
  const userStatus = useMemo(
    () =>
      loadingUser
        ? Statuses.loading
        : errorLoadingUser
        ? Statuses.errored
        : userInfo
        ? Statuses.loaded
        : Statuses.notAuthed,
    [loadingUser, userInfo, errorLoadingUser]
  );

  return (
    <UserContext.Provider
      value={{
        userInfo,
        loadingUser,
        errorLoadingUser,
        signInWithGoogle,
        signOut,
        userUid,
        userStatus,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
