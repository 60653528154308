import { Navbar, Stack, Title } from "@mantine/core";
import NavbarLink from "component/NavbarLink";
import { usePagedForm } from "component/PagedForm";

export default function GameNavbar({ title }) {
  const { currentPage } = usePagedForm();

  return (
    <Navbar.Section>
      <Stack spacing="xs">
        <Title>{title}</Title>
        <NavbarLink href={""} active={currentPage === "main"}>
          Main
        </NavbarLink>
        <NavbarLink href={"box"} active={currentPage === "box"}>
          Box Art
        </NavbarLink>
      </Stack>
    </Navbar.Section>
  );
}
