import { useCallback, useState } from "react";
import { Button, Dialog, Group, Stack, TextInput } from "@mantine/core";
import AssetManager from "component/AssetManager";
import PagedForm, { usePagedForm } from "component/PagedForm";

export const emptyGameData = {
  name: "",
  cover: null,
  bottom: null,
  north: null,
  south: null,
  east: null,
  west: null,
};

export function GameForm() {
  return [
    <PagedForm.Page pageKey="main" component={<GameMainForm />} />,
    <PagedForm.Page pageKey="box" component={<GameBoxArtForm />} />,
    <GameFormControls />,
  ];
}

function GameFormControls() {
  const { hasNext, hasPrev, gotoPrev } = usePagedForm();

  return (
    <Group grow mt="md">
      {hasPrev && <Button onClick={gotoPrev}>Prev</Button>}
      <Button type="submit" color={!hasNext && "green"}>
        {hasNext ? "Next" : "Submit"}
      </Button>
    </Group>
  );
}

function GameMainForm() {
  const { getFieldProps } = usePagedForm();

  return <TextInput {...getFieldProps("name")} label="Name" />;
}

function GameBoxArtForm() {
  const { updateData } = usePagedForm();
  const [imageManagerTarget, setImageManagerTarget] = useState(null);
  const handleSelect = useCallback(
    (file) => {
      updateData(imageManagerTarget, file.path);
      setImageManagerTarget(null);
    },
    [imageManagerTarget, updateData]
  );

  return (
    <Stack>
      <Button onClick={() => setImageManagerTarget("cover")}>
        Cover Image
      </Button>
      <Button onClick={() => setImageManagerTarget("bottom")}>
        Bottom Image
      </Button>
      <Button onClick={() => setImageManagerTarget("north")}>
        North Image
      </Button>
      <Button onClick={() => setImageManagerTarget("south")}>
        South Image
      </Button>
      <Button onClick={() => setImageManagerTarget("east")}>East Image</Button>
      <Button onClick={() => setImageManagerTarget("west")}>West Image</Button>
      <Dialog
        opened={!!imageManagerTarget}
        onClose={() => setImageManagerTarget(null)}
      >
        <AssetManager onSelect={handleSelect} />
      </Dialog>
    </Stack>
  );
}
