import { Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export default function NavbarLink({ href, active, children }) {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate(href)}
      variant={active ? "filled" : "outline"}
    >
      {children}
    </Button>
  );
}
