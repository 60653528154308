import { ActionIcon, Card, Flex, Text, Title } from "@mantine/core";
import { IconBrandGoogle } from "@tabler/icons-react";
import PageLayout from "component/PageLayout";
import { UserContext } from "context/UserContext";
import PublicHeader from "headers/PublicHeader";
import { useContext } from "react";

export default function PublicHomePage() {
  return (
    <PageLayout header={<PublicHeader></PublicHeader>}>
      <Flex direction="column" align="center">
        <Title>Welcome to GleeForge!</Title>
        <Text>
          Here, you can create digital versions of your physical games.
        </Text>
        <Card padding="lg">
          <Flex direction="column" gap="sm">
            <Title>Sign In/Register</Title>
            <ExternalAuthBar />
          </Flex>
        </Card>
      </Flex>
    </PageLayout>
  );
}

function ExternalAuthBar() {
  const { signInWithGoogle } = useContext(UserContext);

  return (
    <>
      <Flex justify="center">
        <ActionIcon
          variant="filled"
          color="green"
          onClick={() => signInWithGoogle()}
          title="Authenticate with Google"
        >
          <IconBrandGoogle />
        </ActionIcon>
      </Flex>
    </>
  );
}
