import PageLayout from "component/PageLayout";
import { Statuses, UserContext } from "context/UserContext";
import { useContext } from "react";
import PublicRouter from "./Public";
import UserRouter from "./User";

/**
 * Handles adding the different routers based on auth state.
 */
export default function MainRouter() {
  const { userStatus } = useContext(UserContext);

  switch (userStatus) {
    case Statuses.loading:
      return <PageLayout>Loading...</PageLayout>;
    case Statuses.loaded:
      return <UserRouter />;
    case Statuses.notAuthed:
      return <PublicRouter />;
    case Statuses.errored:
    default:
      return <div>Errored</div>;
  }
}
