import { Button, HoverCard, Image, Stack } from "@mantine/core";
import PageLayout from "component/PageLayout";
import { GameContext } from "context/GameContext";
import UserHeader from "headers/UserHeader";
import { useContext } from "react";
import { Link } from "react-router-dom";

export default function UserHomePage() {
  const { allList: allGames } = useContext(GameContext);

  return (
    <PageLayout header={<UserHeader />}>
      <Stack>
        {allGames?.map((game) => (
          <HoverCard>
            <HoverCard.Target>
              <Button component={Link} to={`/games/view/${game.id}`}>
                {game.name}
              </Button>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Image src={game.cover} maw={150} />
            </HoverCard.Dropdown>
          </HoverCard>
        ))}
        <Button component={Link} to="/games/add">
          Add New Game
        </Button>
      </Stack>
    </PageLayout>
  );
}
