import { ActionIcon } from "@mantine/core";
import { IconLogin } from "@tabler/icons-react";
import { UserContext } from "context/UserContext";
import { useContext } from "react";
import CommonHeader from "./CommonHeader";

export default function PublicHeader({ children }) {
  const { signInWithGoogle } = useContext(UserContext);

  return (
    <CommonHeader>
      {children}
      <ActionIcon
        variant="outline"
        title="Log In"
        onClick={() => signInWithGoogle()}
      >
        <IconLogin />
      </ActionIcon>
    </CommonHeader>
  );
}
