import UserHomePage from "pages/user/Home";
import AddGamePage from "pages/user/games/AddGamePage";
import EditGamePage from "pages/user/games/EditGamePage";
import ViewGamePage from "pages/user/games/ViewGamePage";
import { Navigate, Route, Routes } from "react-router-dom";

export default function UserRouter() {
  return (
    <Routes>
      <Route path="/" element={<UserHomePage />} />
      <Route path="/games/add/*" element={<AddGamePage />} />
      <Route path="/games/view/:id" element={<ViewGamePage />} />
      <Route path="/games/edit/:id/*" element={<EditGamePage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
