import { AppShell, Group, Header, Navbar } from "@mantine/core";

export default function PageLayout({ navbar, header, children }) {
  return (
    <AppShell
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
      header={
        header && (
          <Header height={60}>
            <Group sx={{ height: "100%" }} px={20}>
              {header}
            </Group>
          </Header>
        )
      }
      navbar={
        navbar && (
          <Navbar width={{ base: 300 }} p="xs">
            {navbar}
          </Navbar>
        )
      }
    >
      {children}
    </AppShell>
  );
}
