import { MantineProvider } from "@mantine/core";
import AssetProvider from "context/AssetContext";
import GameProvider from "context/GameContext";
import UserProvider from "context/UserContext";
import { Toaster } from "react-hot-toast";
import { HashRouter } from "react-router-dom";
import useThemeDetector from "react-theme-hook";
import MainRouter from "routers/Main";

/**
 * Holds the global contexts.
 */
export default function App() {
  const isDarkTheme = useThemeDetector();

  return (
    <HashRouter>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ colorScheme: isDarkTheme ? "dark" : "light" }}
      >
        <UserProvider>
          <AssetProvider>
            <GameProvider>
              <MainRouter />
              <Toaster />
            </GameProvider>
          </AssetProvider>
        </UserProvider>
      </MantineProvider>
    </HashRouter>
  );
}
