import {
  Button,
  FileInput,
  Flex,
  HoverCard,
  Image,
  Stack,
} from "@mantine/core";
import { AssetContext } from "context/AssetContext";
import { useCallback, useContext, useState } from "react";

export default function AssetManager({ onSelect }) {
  const { upload, fileList } = useContext(AssetContext);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUpload = useCallback(async () => {
    await upload(selectedFile);
    setSelectedFile(null);
  }, [selectedFile, upload]);

  return (
    <Stack>
      {fileList.map((file) => (
        <HoverCard>
          <HoverCard.Target>
            <Button onClick={() => onSelect(file)}>{file.name}</Button>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Image src={file.path} maw={150} />
          </HoverCard.Dropdown>
        </HoverCard>
      ))}
      <Flex gap="sm">
        <FileInput
          placeholder="Upload"
          value={selectedFile}
          onChange={(f) => setSelectedFile(f)}
          sx={{ flexGrow: 1 }}
          accept="image/*"
        />
        <Button onClick={handleUpload}>Upload</Button>
      </Flex>
    </Stack>
  );
}
