import PageLayout from "component/PageLayout";
import { GameContext } from "context/GameContext";
import UserHeader from "headers/UserHeader";
import { useCallback, useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PagedForm from "component/PagedForm";
import { GameForm, emptyGameData } from "forms/Game";
import GameNavbar from "navbars/GameNavbar";

export default function EditGamePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { modify: modifyGame, allById: gamesById } = useContext(GameContext);
  const game = useMemo(() => gamesById[id], [gamesById, id]);

  const handleEdit = useCallback(
    async (data) => {
      await modifyGame(id, data);
      navigate("/games");
    },
    [modifyGame, id, navigate]
  );

  return (
    <PagedForm
      defaultData={game || emptyGameData}
      onSubmit={handleEdit}
      formElements={GameForm()}
      onlyEdits
    >
      <PageLayout header={<UserHeader />} navbar={<GameNavbar />}>
        <PagedForm.Outlet />
      </PageLayout>
    </PagedForm>
  );
}
